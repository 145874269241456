import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaShoppingBag, FaHeadset, FaUserPlus, FaSignInAlt, FaGlobe, FaBars, FaTimes } from 'react-icons/fa';
import styles from './Header.module.css';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  // Detectar rolagem
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`${styles.header} ${scrolled || !isHomePage ? styles.scrolled : ''}`}>
      <Link to="/" className={styles.clickkTitle}>Clickk</Link>
      <div className={styles.mobileMenuIcon} onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </div>
      <nav className={`${styles.nav} ${menuOpen ? styles.navActive : ''}`}>
        <a
          href="https://www.midout.com.br"
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.navLink} ${styles.pontoDigital}`}
          onClick={() => setMenuOpen(false)}
        >
          <FaGlobe className={styles.icon} /> Ponto Digital
        </a>
        <div className={styles.divider}></div>
        <Link to="/bag" className={styles.navLink} onClick={() => setMenuOpen(false)}>
          <FaShoppingBag className={styles.icon} /> Planos
        </Link>
        <Link to="/support" className={styles.navLink} onClick={() => setMenuOpen(false)}>
          <FaHeadset className={styles.icon} /> Suporte
        </Link>
        <Link to="/signup" className={styles.navLink} onClick={() => setMenuOpen(false)}>
          <FaUserPlus className={styles.icon} /> Inscrição
        </Link>
        <Link to="/login" className={`${styles.navLink} ${styles.loginLink}`} onClick={() => setMenuOpen(false)}>
          <FaSignInAlt className={styles.icon} /> Login
        </Link>
      </nav>
    </header>
  );
};

export default Header;
