// src/pages/Market.js
import React from 'react';

const Market = () => {
  return (
    <div>
      <h1>Market Page</h1>
      <p>Welcome to the Market page!</p>
    </div>
  );
};

export default Market;
