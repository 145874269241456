import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaChevronLeft,
  FaChevronRight,
  FaEnvelope,
  FaPlay,
  FaInstagram,
  FaTwitter,
} from 'react-icons/fa';
import { SiThreads } from 'react-icons/si';
import './midia.css';

const bannerList = [
  'CARROSSEL001.png',
  'CARROSSEL002.png',
  'CARROSSEL003.png',
  'CARROSSEL004.png',
  'CARROSSEL005.png',
  'CARROSSEL006.png',
  'CARROSSEL007.png',
  'CARROSSEL008.png',
  'banner04.png',
];

const imageList = [
  'CARROSSEL001.png',
  'CARROSSEL002.png',
  'CARROSSEL003.png',
  'CARROSSEL004.png',
  'CARROSSEL005.png',
  'CARROSSEL006.png',
  'CARROSSEL007.png',
  'CARROSSEL008.png',
  'CARROSSEL009.png',
];

const videoList = [
  require('../assets/movie_02.mov'),
  require('../assets/movie_03.mov'),
  require('../assets/movie_04.mov'),
];

const Midia = () => {
  const navigate = useNavigate();
  const [isMuted, setIsMuted] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(0);
  const videoRef = useRef(null);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [visibleSections, setVisibleSections] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  const handleGoToMarket = () => {
    navigate('/bag');
  };

  const handleGoToPresentation = () => {
    navigate('/presentation');
  };

  const handleGoToContact = () => {
    navigate('/contact');
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleNextBanner = () => {
    setCurrentBanner((prevBanner) => (prevBanner + 1) % bannerList.length);
  };

  const handlePrevBanner = () => {
    setCurrentBanner(
      (prevBanner) => (prevBanner - 1 + bannerList.length) % bannerList.length
    );
  };

  const handleVideoEnd = () => {
    setCurrentVideo((prevVideo) => (prevVideo + 1) % videoList.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextBanner();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleVideoVisibility = () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        const bounding = videoElement.getBoundingClientRect();
        const isVisible =
          bounding.top >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);

        // Somente reproduz o vídeo automaticamente se não for em dispositivo móvel
        if (isVisible && !isMobile) {
          videoElement.play().catch(error => {
            console.error("Não foi possível reproduzir o vídeo automaticamente:", error);
          });
        } else {
          videoElement.pause();
        }
      }
    };

    window.addEventListener('scroll', handleVideoVisibility);
    return () => {
      window.removeEventListener('scroll', handleVideoVisibility);
    };
  }, [isMobile]);

  // Detecta se o dispositivo é móvel
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  // Função para observar e carregar seções conforme o usuário rola
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleSections((prevSections) => ({
              ...prevSections,
              [entry.target.id]: true,
            }));
          }
        });
      },
      { threshold: 0.25 }
    );

    document.querySelectorAll('.midia-section').forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="midia-container">
      <div className="header-space"></div>

      <section className="midia-banner">
        <button className="banner-nav prev" onClick={handlePrevBanner}>
          <FaChevronLeft />
        </button>
        <img
          src={require(`../assets/${bannerList[currentBanner]}`)}
          alt={`Banner ${currentBanner + 1}`}
          className="full-banner"
        />
        <button className="banner-nav next" onClick={handleNextBanner}>
          <FaChevronRight />
        </button>
      </section>

      <section className="midia-intro subtle-color">
        <div className="intro-content centered section-container">
          <h1 className="section-title">
            Fique à Frente nas Tendências de Mídia e Publicidade
          </h1>
          <p className="section-description">
            No mundo da mídia digital, estar um passo à frente é essencial para garantir o sucesso das suas campanhas. Com o Clickk, você tem acesso a uma plataforma inovadora que transforma a forma como você gerencia e otimiza suas campanhas publicitárias. Simples, rápida e totalmente adaptada às suas necessidades.
          </p>

          <div className="qr-code-container">
            <div className="qr-code">
              <img src={require('../assets/hashios.png')} alt="QR Code iOS" />
            </div>
            <div className="qr-code">
              <img src={require('../assets/hashandroid.png')} alt="QR Code Android" />
            </div>
          </div>

          <div className="buttons">
            <button className="primary-btn" onClick={handleGoToMarket}>
              <FaEnvelope style={{ marginRight: '8px' }} /> Adquira Aqui
            </button>
            <button onClick={handleGoToPresentation} className="secondary-btn">
              <FaPlay style={{ marginRight: '8px' }} /> Teste Agora
            </button>
          </div>
        </div>
      </section>

      <section className="midia-carousel">
        <div className="carousel-container">
          {imageList.concat(imageList).map((image, index) => (
            <div key={index} className="carousel-item">
              <img src={require(`../assets/${image}`)} alt={`carousel ${index}`} />
            </div>
          ))}
        </div>
      </section>

      {/* Seções que aparecem conforme a rolagem */}
      <section
        className={`midia-section section-container subtle-color ${
          visibleSections['section1'] ? 'visible' : ''
        }`}
        id="section1"
      >
        <div className="section-content">
          <div className="image-container bordered">
            <img
              src={require('../assets/appscreen_01.png')}
              alt="App Screen 1"
              className="small-app-image"
            />
          </div>
          <div className="text-container">
            <h2 className="larger-title">Seja Inspirado pela Simplicidade e Eficiência</h2>
            <p className="larger-text">
            O Clickk oferece uma interface intuitiva que coloca o controle total de suas campanhas publicitárias, monitoramento de mídia e gestão financeira na palma da sua mão. Tudo de maneira rápida, acessível e diretamente no seu dispositivo. Simplifique suas operações e maximize seus resultados com uma experiência visual pensada para você.
            </p>
          </div>
        </div>
      </section>

      <section
        className={`midia-section reverse section-container subtle-color ${
          visibleSections['section2'] ? 'visible' : ''
        }`}
        id="section2"
      >
        <div className="section-content">
          <div className="text-container">
            <h2 className="larger-title">Crie e Gerencie Campanhas com Facilidade</h2>
            <p className="larger-text">
            A plataforma de campanhas da Clickk simplifica todo o processo de criação, edição e publicação de campanhas publicitárias. Em poucos passos, você está no controle completo de suas estratégias, com acesso a ferramentas de otimização que tornam a gestão mais eficiente do que nunca. Maximizar o impacto das suas campanhas nunca foi tão fácil.
            </p>
          </div>
          <div className="image-container bordered">
            <img
              src={require('../assets/appscreen_02.png')}
              alt="App Screen 2"
              className="small-app-image"
            />
          </div>
        </div>
      </section>

      <section
        className={`midia-section section-container subtle-color ${
          visibleSections['section3'] ? 'visible' : ''
        }`}
        id="section3"
      >
        <div className="section-content">
          <div className="image-container bordered">
            <img
              src={require('../assets/appscreen_03.png')}
              alt="App Screen 3"
              className="small-app-image"
            />
          </div>
          <div className="text-container">
            <h2 className="larger-title">Seu Centro Criativo para Publicidade</h2>
            <p className="larger-text">
            O Studio da Clickk é o seu centro de comando para a criação e personalização de campanhas publicitárias. Ajuste imagens, vídeos e outros conteúdos de forma simples e intuitiva para garantir que suas campanhas alcancem o máximo potencial. Com todas as ferramentas necessárias em um só lugar, você terá o controle total para criar mídias impactantes e eficientes.
            </p>
          </div>
        </div>
      </section>

      <section
        className={`midia-section reverse section-container subtle-color ${
          visibleSections['section4'] ? 'visible' : ''
        }`}
        id="section4"
      >
        <div className="section-content">
          <div className="text-container">
            <h2 className="larger-title">O Controle na Palma da Mão</h2>
            <p className="larger-text">
            Gerencie seu saldo, transferências e recebimentos de forma fácil e segura com a carteira digital integrada do Clickk. Tudo diretamente no aplicativo, oferecendo simplicidade e segurança para que você possa focar no que realmente importa: o sucesso das suas campanhas.
            </p>
          </div>
          <div className="image-container bordered">
            <img
              src={require('../assets/appscreen_04.png')}
              alt="App Screen 4"
              className="small-app-image"
            />
          </div>
        </div>
      </section>

      <section className="midia-infinite-inspiration black-section">
        <div className="video-container" onClick={toggleMute}>
          <video
            ref={videoRef}
            src={videoList[currentVideo]}
            autoPlay={!isMobile} // Não auto-play em dispositivos móveis
            muted={isMobile || isMuted} // Som sempre desativado em dispositivos móveis
            onEnded={handleVideoEnd}
            className="fixed-video"
            playsInline // Necessário para reprodução em dispositivos iOS
            controls={isMobile} // Exibe os controles para dispositivos móveis
          />
        </div>
        <button className="video-section-btn" onClick={handleGoToPresentation}>
          Veja Mais
        </button>
      </section>

      <section
        className={`midia-section section-container subtle-color ${
          visibleSections['section5'] ? 'visible' : ''
        }`}
        id="section5"
      >
        <div className="section-content">
          <div className="image-container bordered">
            <img
              src={require('../assets/appscreen_05.png')}
              alt="Mídia Out Of Home"
              className="small-app-image"
            />
          </div>
          <div className="text-container">
            <h2 className="larger-title">Exiba Suas Campanhas em Qualquer Lugar</h2>
            <p className="larger-text">
            Com a Clickk, suas campanhas não têm limites. Exiba seus conteúdos publicitários em qualquer tela conectada à internet, garantindo que sua mensagem chegue ao público certo, onde quer que ele esteja.
            </p>
          </div>
        </div>
      </section>

      <section
        className={`midia-section reverse section-container subtle-color ${
          visibleSections['section6'] ? 'visible' : ''
        }`}
        id="section6"
      >
        <div className="section-content">
          <div className="text-container">
            <h2 className="larger-title">Escolha a Tela Perfeita para o Seu Negócio</h2>
            <p className="larger-text">
            Seja utilizando suas próprias telas ou uma das nossas opções interativas, você tem o poder de criar uma experiência visual imersiva e de alta qualidade em seu estabelecimento. Tudo pensado para maximizar o impacto da sua campanha.
            </p>
          </div>
          <div className="image-container bordered">
            <img
              src={require('../assets/appscreen_06.png')}
              alt="Escolha de Tela"
              className="small-app-image"
            />
          </div>
        </div>
      </section>

      <section
        className={`midia-section section-container subtle-color ${
          visibleSections['section7'] ? 'visible' : ''
        }`}
        id="section7"
      >
        <div className="section-content">
          <div className="image-container bordered">
            <img
              src={require('../assets/appscreen_07.png')}
              alt="Gerenciamento Remoto"
              className="small-app-image"
            />
          </div>
          <div className="text-container">
            <h2 className="larger-title">Controle Total na Palma da Mão</h2>
            <p className="larger-text">
            Gerencie suas campanhas e telas conectadas de qualquer lugar, diretamente do seu celular. Ajuste conteúdos, acesse relatórios e tenha controle completo da sua estratégia de mídia de forma rápida, prática e eficiente.
            </p>
          </div>
        </div>
      </section>

      <section className="midia-creativity colorful-section full-width-section">
        <div className="creativity-content centered">
          <h2 className="larger-title">Liberte Sua Criatividade Sem Limites</h2>
          <p className="larger-text">
          Nosso produto foi projetado para desbloquear possibilidades infinitas de criação e inovação. Com a Clickk, você pode criar campanhas dinâmicas e interativas, personalizadas para atender suas necessidades específicas, sem barreiras técnicas. Dê vida às suas ideias com total liberdade e facilidade.
          </p>
          <button className="cta-btn" onClick={handleGoToContact}>
            Entre em Contato
          </button>

          <div className="interest-form">
            <h3>Escreva para Nós</h3>
            <form>
              <input type="text" placeholder="Seu nome*" required />
              <input type="email" placeholder="Seu e-mail*" required />
              <textarea placeholder="Informe mais detalhes sobre o que deseja..." required />
              <button type="submit" className="cta-btn">
                Enviar
              </button>
            </form>
          </div>
        </div>
      </section>

      <footer className="midia-footer">
        <div className="footer-content">
          <div className="footer-left">
            <h3>Clickk Operações Digitais</h3>
            <p>CNPJ: 51.352.043/0001-77</p>
            <p>Higienópolis, São Paulo - SP</p>
          </div>
          <div className="footer-right">
            <h3>Contato</h3>
            <p>Email: <a href="mailto:contato@clickk.com.br">contato@clickk.com.br</a></p>
            {/* <div className="social-icons">
              <a href="https://instagram.com" aria-label="Instagram">
                <FaInstagram />
              </a>
              <a href="https://twitter.com" aria-label="X">
                <FaTwitter />
              </a>
              <a href="https://threads.net" aria-label="Threads">
                <SiThreads />
              </a>
            </div> */}
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024 Clickk Midia. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default Midia;
