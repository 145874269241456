import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Social from './pages/Social';
import Midia from './pages/Midia';
import Market from './pages/Market';
import Layout from './components/Layout';
import Presentation from './pages/Presentation'; // Importar a página Presentation
import Bag from './pages/Bag'; // Importar a página Bag
import Support from './pages/Support'; // Importar a página Support
import SignUp from './pages/SignUp'; // Importar a página SignUp (Tela de Inscrição)
import Login from './pages/Login'; // Importar a página Login (Tela de Login)

// Componente para redirecionar a partir da Home
function RedirectToMidia() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/midia'); // Redireciona para a página Midia
  }, [navigate]);

  return null; // Não renderiza nada
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirecionar para Midia */}
        <Route path="/" element={<RedirectToMidia />} />

        {/* Rotas que usam Layout com Header */}
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route path="social" element={<Social />} />
                <Route path="midia" element={<Midia />} />
                <Route path="market" element={<Market />} />
                <Route path="signup" element={<SignUp />} /> {/* Adicionando a rota de inscrição */}
                <Route path="login" element={<Login />} /> {/* Adicionando a rota de login */}
              </Routes>
            </Layout>
          }
        />

        {/* Rota para Presentation sem Header */}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/bag" element={<Bag />} />
        
        {/* Rota para a página de Suporte */}
        <Route path="/support" element={<Support />} />
      </Routes>
    </Router>
  );
}

export default App;
