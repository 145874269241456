import React, { useState } from 'react';
import './login.css'; // Importando o arquivo CSS separado

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const [errors, setErrors] = useState({});

  // Função para lidar com as alterações no formulário
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Função para enviar o formulário
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validações básicas
    if (!formData.username || !formData.password) {
      setErrors({ message: 'Todos os campos são obrigatórios.' });
      return;
    }

    // Aqui você integraria com o AWS Amplify futuramente
    console.log('Login data submitted:', formData);
  };

  return (
    <div className="login-container">
      <h1 className="login-title">Login</h1>

      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Nome de Usuário:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="form-input"
            placeholder="Digite seu nome de usuário"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Senha:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="form-input"
            placeholder="Digite sua senha"
          />
        </div>

        {errors.message && <span className="error-message">{errors.message}</span>}

        <button type="submit" className="login-button">Entrar</button>
      </form>
    </div>
  );
};

export default Login;
