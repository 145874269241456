// src/pages/Social.js
import React from 'react';

const Social = () => {
  return (
    <div>
      <h1>Social Page</h1>
      <p>Welcome to the Social page!</p>
    </div>
  );
};

export default Social;
