import React, { useState } from 'react';
import './signup.css'; // Importando o arquivo CSS separado

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});

  // Função para lidar com as alterações no formulário
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validação para garantir que o username seja composto apenas de números
    if (name === 'username' && isNaN(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: 'O nome de usuário deve conter apenas números.'
      }));
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: null
      }));
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Função para enviar o formulário
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validações básicas
    if (!formData.username || !formData.email || !formData.password) {
      setErrors({ message: 'Todos os campos são obrigatórios.' });
      return;
    }

    // Aqui você integraria com o AWS Amplify futuramente
    console.log('Form data submitted:', formData);
  };

  return (
    <div className="signup-container">
      <h1 className="signup-title">Criar Conta</h1>

      <form className="signup-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Nome de Usuário (Apenas números):</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="form-input"
            placeholder="Digite apenas números"
          />
          {errors.username && <span className="error-message">{errors.username}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="email">E-mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            placeholder="Digite seu e-mail"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Senha:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="form-input"
            placeholder="Digite sua senha"
          />
        </div>

        {errors.message && <span className="error-message">{errors.message}</span>}

        <button type="submit" className="signup-button">Cadastrar</button>
      </form>
    </div>
  );
};

export default SignUp;
