import React from 'react';
import Header from '../components/Header'; // Certifique-se de importar corretamente o Header
import './support.css'; // Importando o arquivo CSS separado

const Support = () => {
  return (
    <div className="support-container">
      {/* Header */}
      <Header />
      
      {/* Conteúdo da página de Suporte */}
      <div className="support-message">
        <h1 className="support-title">Suporte ao Cliente</h1>
        <p className="support-description">
          O suporte está temporariamente disponível exclusivamente para usuários cadastrados. Para solicitar assistência, 
          por favor, faça o login no aplicativo e envie sua solicitação diretamente pelo sistema de suporte do app.
        </p>
        <p className="support-thankyou">Agradecemos sua compreensão e estamos aqui para ajudar!</p>
      </div>
    </div>
  );
};

export default Support;
