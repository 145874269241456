import React, { useState } from 'react';
import Header from '../components/Header'; // Certifique-se de importar corretamente o Header
import './bag.css';

// Importando as imagens diretamente
import coinGif from '../assets/coin.gif';
import ponto_digital_01 from '../assets/ponto_digital_01.png';
import ponto_digital_02 from '../assets/ponto_digital_02.png';
import ponto_digital_03 from '../assets/ponto_digital_03.png';
import ponto_digital_04 from '../assets/ponto_digital_04.png';

const images = {
  'coin.gif': coinGif,
  'ponto_digital_01.png': ponto_digital_01,
  'ponto_digital_02.png': ponto_digital_02,
  'ponto_digital_03.png': ponto_digital_03,
  'ponto_digital_04.png': ponto_digital_04,
};

const products = [
  {
    id: 1,
    name: 'Assinatura Digital',
    price: 'R$ 69',
    image: 'coin.gif',
    description:
      'Este plano oferece uma ampla gama de funcionalidades que incluem a capacidade de gerenciar campanhas publicitárias digitais de forma interativa e automática, proporcionando relatórios em tempo real e maior engajamento com seu público-alvo.',
    carouselImages: [
      'ponto_digital_01.png',
      'ponto_digital_02.png',
      'ponto_digital_03.png',
      'ponto_digital_04.png',
    ],
  },
  {
    id: 2,
    name: 'Assinatura Digital',
    price: 'R$ 59, no Plano Anual',
    image: 'coin.gif',
    description:
      'Este plano oferece uma ampla gama de funcionalidades que incluem a capacidade de gerenciar campanhas publicitárias digitais de forma interativa e automática, proporcionando relatórios em tempo real e maior engajamento com seu público-alvo.',
    carouselImages: [
      'ponto_digital_01.png',
      'ponto_digital_02.png',
      'ponto_digital_03.png',
      'ponto_digital_04.png',
    ],
  },
  {
    id: 3,
    name: 'Interativa 24"',
    price: 'R$ 279, no Plano Anual',
    image: 'coin.gif',
    description:
      'Esta tela de 24" é ideal para exibir conteúdos multimídia interativos em tempo real, com controle remoto via celular. Ideal para ambientes corporativos e eventos que exigem flexibilidade e impacto visual.',
    carouselImages: ['ponto_digital_01.png', 'ponto_digital_01.png'],
  },
  {
    id: 4,
    name: 'Interativa 43"',
    price: 'R$ 399, no Plano Anual',
    image: 'coin.gif',
    description:
      'Esta tela de 43" é perfeita para campanhas que precisam de grande visibilidade e alta definição, garantindo que sua mensagem alcance o público com clareza e impacto.',
    carouselImages: ['ponto_digital_01.png', 'ponto_digital_01.png'],
  },
  {
    id: 5,
    name: 'Interativa 50"',
    price: 'R$ 479, no Plano Anual',
    image: 'coin.gif',
    description:
      'Com 50", esta tela proporciona uma experiência imersiva, ideal para locais de grande circulação. Sua definição cristalina é perfeita para destacar campanhas visuais e interativas.',
    carouselImages: ['ponto_digital_01.png', 'ponto_digital_01.png'],
  },
  {
    id: 6,
    name: 'Interativa Personalizada',
    image: 'coin.gif',
    description:
      'Personalize sua experiência visual com esta tela interativa sob medida, que se adapta às necessidades de sua campanha para maximizar o impacto e a flexibilidade.',
    carouselImages: ['ponto_digital_01.png', 'ponto_digital_01.png'],
  },
];

const Bag = () => {
  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [mainImage, setMainImage] = useState(images[selectedProduct.image]);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setMainImage(images[product.image]); // Reseta a imagem principal ao trocar de produto
  };

  const handleCarouselClick = (image) => {
    setMainImage(images[image]); // Atualiza a imagem principal ao clicar no carrossel
  };

  return (
    <div className="bag-container">
      {/* Header */}
      <Header />

      {/* Mensagem de compras suspensas */}
      <div className="suspended-message">
        As compras estão temporariamente suspensas. Agradecemos sua compreensão.
      </div>

      {/* Detalhes do Produto Selecionado no Topo */}
      <div className="product-top-details">
        {/* Lado Esquerdo: Imagem do Produto */}
        <div className="top-left">
          <img
            src={mainImage}
            alt={selectedProduct.name}
            className="top-product-image"
          />

          {/* Contêiner de preço abaixo da imagem */}
          <div className="price-box">
            <p className="top-product-price">
              <strong>{selectedProduct.price}</strong>
            </p>
          </div>
        </div>

        {/* Lado Direito: Informações do Produto */}
        <div className="top-right">
          <div className="info-container">
            <h2 className="product-title">{selectedProduct.name}</h2>
            <p className="description">{selectedProduct.description}</p>
          </div>
          {/* Carrossel de Imagens fixado na parte inferior */}
          <div className="product-carousel">
            {selectedProduct.carouselImages &&
              selectedProduct.carouselImages.map((image, index) => (
                <img
                  key={index}
                  src={images[image]}
                  alt={`${selectedProduct.name} ${index + 1}`}
                  className="carousel-image"
                  onClick={() => handleCarouselClick(image)}
                />
              ))}
          </div>
        </div>
      </div>

      {/* Scroll Horizontal dos Produtos */}
      <div className="scroll-container">
        <div className="product-scroll">
          {products.map((product) => (
            <div
              className="product-card"
              key={product.id}
              onClick={() => handleProductClick(product)}
            >
              <img
                src={images[product.image]}
                alt={product.name}
                className="product-image"
              />
              <h3>{product.name}</h3>
              <p className="product-price">
                <strong>{product.price}</strong>
              </p>
              <button className="select-button">Selecionar</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bag;
